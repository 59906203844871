import React from "react";
import { Form, Input, Button } from "antd";

const layout = {
  wrapperCol: { span: 16 }
};

const tailLayout = {
  wrapperCol: { offset: 4 }
};

export const Command = props => {
  const { onSendCommand, onSendCommandError, playerId } = props;

  return (
    <div className={"forms"}>
      <h1>Interactive</h1>
      <Form
        {...layout}
        name="basic"
        initialValues={{
          type: "messageAdded",
          playerId
        }}
        onFinish={onSendCommand}
        onFinishFailed={onSendCommandError}
        size="large"
      >
        <Form.Item label="BS Player ID" name="playerId">
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          rules={[{ required: true, message: "Please input the type!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Creative ID"
          name="creativeId"
          rules={[{ required: true, message: "Please input the creativeId!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Anchor"
          name="anchor"
          rules={[{ required: true, message: "Please input the argument!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Text" name="text">
          <Input />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Send
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
