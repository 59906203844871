import React, { useState, useEffect } from "react";
import axios from "axios";

import { Login, Command } from "./components";

const socketUrl = process.env.REACT_APP_D3CKSOCKET;

const setStorage = (name, data) => window.localStorage.setItem(name, data);
const getStorage = name => window.localStorage.getItem(name);

const objToString = data => JSON.stringify(data);

export const FormContainer = () => {
  const [user, setUser] = useState({
    token: null,
    isFetching: false,
    playerId: null,
    socket: null,
    error: null,
    message: {}
  });

  useEffect(() => {
    const token = getStorage("token");
    const playerId = getStorage("playerId");
    if (!token) {
      setUser(s => ({ ...s, isFetching: false, token: null }));
    } else {
      connectWebSocket(socketUrl);
      setUser(s => ({ ...s, isFetching: false, token: token, playerId }));
    }
  }, [user.isFetching]);

  const connectWebSocket = host => {
    const ws = new WebSocket(host.replace(/^http/, "ws"));

    ws.onopen = () => {
      console.log("Connected to websocket!");
      setUser(s => ({ ...s, socket: ws }));
    };
  };

  const onLogin = async values => {
    const { username: user, password, playerId } = values;

    const res = await axios.post(`${socketUrl}/auth`, {
      user,
      password,
      playerId
    });

    setStorage("token", res.data);
    setStorage("playerId", playerId);

    setUser(s => ({ ...s, isFetching: true, playerId }));
    connectWebSocket(socketUrl);
  };

  const onLoginError = errorInfo =>
    setUser(s => ({ ...s, isFetching: false, error: errorInfo }));

  const onSendCommand = async values => {
    const { type, anchor, text, creativeId, playerId } = values;

    const data = {
      type,
      payload: {
        creativeId,
        argument: { anchor, text },
        playerId
      }
    };
    return user.socket.send(objToString(data));
  };

  const onSendCommandError = errorInfo =>
    setUser(s => ({ ...s, isFetching: false, error: errorInfo }))

  if (!user.token)
    return (
      <>
        {user.error && <h1>`${user.error}`</h1>}
        <Login onLogin={onLogin} onLoginError={onLoginError} />
      </>
    );

  return (
    <>
      {user.error && <h1>`${user.error}`</h1>}
      <Command
        onSendCommand={onSendCommand}
        onSendCommandError={onSendCommandError}
        playerId={user.playerId}
      />
    </>
  );
};
