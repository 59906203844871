import React from "react";
import { Form, Input, Button } from "antd";

const layout = {
  wrapperCol: { span: 16 }
};

const tailLayout = {
  wrapperCol: { offset: 4 }
};

export const Login = props => {
  const { onLogin, onLoginError } = props;

  return (
    <div className={"forms"}>
      <h1>Interactive</h1>
      <Form
        {...layout}
        name="basic"
        initialValues={{}}
        onFinish={onLogin}
        onFinishFailed={onLoginError}
        size="large"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="BS Player ID"
          name="playerId"
          rules={[{ required: true, message: "Please input the player ID!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
