import React from "react";
import { FormContainer } from "./components/form";

function App() {
  return (
    <div className={"container"}>
      <FormContainer />
    </div>
  );
}

export { App };
