import React from "react";
import "whatwg-fetch";
import { render } from "react-dom";
import { App } from "./App";

import "antd/dist/antd.min.css";
import "./App/styles/themes.css";

const renderApp = () => (render(<App />, document.getElementById("root")));

renderApp();

if ("serviceWorker" in navigator && process.env.NODE_ENV === "production") {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/sw.js")
      .then(registration => {
        console.log("SW registered: ", registration)
    })
      .catch(registrationError => {
        console.log("SW registration failed: ", registrationError);
      });
  });
}
